<template>
    <div class='module-possiblepie compose'>
        <h4 class='budget-title'>{{report.category.display}}</h4>
        <canvas class='budget-pie' :id="chartId" width="400" height="400"></canvas>
        <table class='widget-table fill'>
            <tr v-if="false">
                <td></td>
                <td class='money budget-cellhead'>Amount</td>
            </tr>
            <tr>
                <td class='budget-cellhead'>Spent</td>
                <td  class='money'>{{displayAmount(report.totalSpent.valueOf())}}</td>
            </tr>
            <tr>
                <td class='budget-cellhead'>Planned</td>
                <td  class='money'>{{displayAmount(report.totalPlanned.valueOf())}}</td>
            </tr>
            <tr class='fill ' style= "font-weight:bold;">
                <td class='budget-cellhead'>Funded Amount</td>
                <td  class='money'>{{displayAmount(report.totalBudget.valueOf())}}</td>
            </tr>
            <tr :style="styleUnspent" class='rounded-lg'>
                <td class='budget-cellhead'>Unspent</td>
                <td class='money'>{{displayAmount(report.totalUnspent.valueOf())}}</td>
            </tr>
            
        </table>
        <MeAction class='mt-2' :full="true" @click="onEstimator" :labelText="'Estimator'"></MeAction>
        <ObjectPopup :title="report.category.display" class='popup-estimator' @toggler="onToggler">
            <template v-slot:body>
                <LiveEstimator :reportItem="report">
                </LiveEstimator>
            </template>
        </ObjectPopup>
    </div>
</template>

<script lang="ts">
import { Chart } from "chart.js"
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator"
import { uuid } from "vue-uuid";
import { ReportItem } from "possibleme-db/lib/util/BusinessUtil";
import ObjectPopup from "@/components/phoenix/ObjectPopup.vue"
import LiveEstimator from "@/components/possibleme/widgets/LiveEstimator.vue"
import { PopupToggler } from "../phoenix/ObjectPopup.vue";
import { displayMoney } from "possibleme-db/lib/util/BigNum"
import MeAction from "../possibleme/helper/MeAction.vue"

@Options({
    components : {
        ObjectPopup,
        LiveEstimator,
        MeAction
    }
})
export default class PossiblePie extends Vue {
    chartId = uuid.v4()
    @Prop()
    report! : ReportItem

    @Prop()
    index! : number

    classUnspent! : string

    toggler! : PopupToggler

    styleUnspent = {
        "background-color": "var(--color--accent)",
        "color":"white",
    }
    onToggler(toggler: PopupToggler) {
        this.toggler = toggler
    }
    onEstimator() {
        this.toggler(true)
    }

    mounted() {
        let ctx = document.getElementById(this.chartId) as any;
        const context = ctx.getContext("2d");

        const spent = Number(this.report.totalSpent.valueOf()) 
        const planned = Number(this.report.totalPlanned.valueOf()) 
        let unspent = Number(this.report.totalUnspent.valueOf()) 
        let remain = 0;
        const unspentAbs = Math.abs(unspent)
        if(unspent >= -0.05){
            unspent = unspentAbs 
        }
        else {
            unspent = 0
            remain = unspentAbs
        }
        const reportData : any = {
            type : "pie",
            data : {
                labels : ["Spent", "Planned", "Unspent"],
                datasets : [{
                    data : [spent, planned, unspent, remain],
                    backgroundColor: [
                        '#37abc8',
                        '#003380',
                        '#aad400',
                        '#f23873',
                    ]
                }],
            },
        }
        if(remain > 0)
            reportData.data.labels[3] = "Over Budget"
        const myChart = new Chart(context, reportData)
        
    }
    displayAmount = displayMoney
}
</script>
<style scoped>
.budget-title {
    height: 64px;
    text-align: center;
}
.budget-cellhead {
    font-weight: bold;
}
.estimator {
    width: 20px;
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
}
.budget-pie {
    z-index: -10;
}
.popup-estimator {
    z-index: 10;
    
}
</style>