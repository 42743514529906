<template>
    <div class='dialog--child'>
        <div v-if="false">
            
            <h5>spent: {{reportItem.totalSpent.valueOf()}}</h5>
            <h5>planned: {{reportItem.totalPlanned.valueOf()}}</h5>
            <h5>unspent: {{reportItem.totalUnspent.valueOf()}}</h5>
            
            <h3>Cost: $ {{totalCost}}</h3>
        </div>
        <div class='remaining'>
            <h4 class='mt-auto mb-auto inline remain'>Remaining</h4>
            <h4 class='mt-auto mb-auto headerRemain'> $ {{totalRemaining.toFixed(2)}}</h4>
        </div>
        
        <div class='estimate-form shift-down__shrink'>
            <input class='fill' v-model="formSupport" type="text" name="estimate" placeholder="Support Name">
           
        </div>
        
        <MeAction class='mt-2' :full="true" @click="onAddSupport" :labelText="'Add Support Label'" ></MeAction>
        
        <div class='fill' v-for="(item, index) in estimates" :key="`estb:${index}`">
            <div class='support-name fill'>
                <input class='support' v-model="item.name" type="text" placeholder="Support Name">
                $ <input @input="onSessions" v-model="item.amount"  class='price' type="number" placeholder="Price">
            </div>
            <div class='sessions'>{{item.sessions}}</div>
             <input class='fill' v-model="item.sessions" @input="onSessions(item)" type="range" min="1" max="50" >
           
        </div>
        <table v-if="false" class='widget-table shift-down'>
            <tr>
                <th>Support</th>
                <th>Price</th>
                <th>Amount</th>
                <th>Sessions</th>
            </tr>
            <tr v-for="(item, index) in estimates" :key="`est:${index}`">
                <input v-model="item.name" type="text" placeholder="Support Name">
                <input @input="onSessions(item)" v-model="item.amount" type="number">
                
                <td></td>
                <input v-model="item.sessions" @input="onSessions(item)" type="range" min="1" max="50" >
               
            </tr> 
            
        </table>
        
    </div>
</template>

<script lang="ts">
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { ReportItem } from "possibleme-db/lib/util/BusinessUtil";
import { Options } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import MeAction from "../helper/MeAction.vue"

type EstimateSupport = {
    name : string,
    amount : number,
    sessions : number
}

@Options({
    components : {
        MeAction
    }
})
export default class LiveEstimator extends FireComponent {
    @Prop()
    reportItem! : ReportItem

    formSupport = ""

    estimates : EstimateSupport[] = []

    totalCost = 0
    totalRemaining = 0

    onAddSupport(){
        this.estimates.push({
            name : this.formSupport,
            amount : 1,
            sessions : 1
        })
        this.formSupport = ""
    }
   
    onSessions(){
        let total = 0;
        for(let i=0; i < this.estimates.length; i++){
            const item = this.estimates[i];
            total += item.amount * item.sessions;
        }
        this.totalCost = total;
        this.totalRemaining = this.reportItem.totalUnspent.toNumber() - this.totalCost
    }

    calcRemaining(){
        return this.reportItem.totalUnspent.valueOf()
    }

    mounted(){
        this.totalRemaining = this.reportItem.totalUnspent.toNumber();
    }

    onUserReady(uid: string | null) : void {
        return;    
    }
    componentClass(): string {
        return "live-estimator"
    }
 
}
</script>

<style scoped>
.remain {
    color: purple;
}

.estimate-form{
    justify-content: space-between;
}
.support-name {
    display: flex;
    justify-content: space-between;
    padding-top: var(--stm--margin);
    padding-bottom: var(--stm--margin);
}
.price {
    width: 20%;
}
.support {
    width: 80%;
}
.sessions {
    width: 100%;
    text-align: center;
    font-size: .8rem;
}
.remaining {
    display: flex;
    justify-content: space-between;
}
.headerRemain {
    color: white;
    background-color: purple;
    border-radius: 1rem;
    padding: .5rem;
}

</style>