
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { ReportItem } from "possibleme-db/lib/util/BusinessUtil";
import { Options } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import MeAction from "../helper/MeAction.vue"

type EstimateSupport = {
    name : string,
    amount : number,
    sessions : number
}

@Options({
    components : {
        MeAction
    }
})
export default class LiveEstimator extends FireComponent {
    @Prop()
    reportItem! : ReportItem

    formSupport = ""

    estimates : EstimateSupport[] = []

    totalCost = 0
    totalRemaining = 0

    onAddSupport(){
        this.estimates.push({
            name : this.formSupport,
            amount : 1,
            sessions : 1
        })
        this.formSupport = ""
    }
   
    onSessions(){
        let total = 0;
        for(let i=0; i < this.estimates.length; i++){
            const item = this.estimates[i];
            total += item.amount * item.sessions;
        }
        this.totalCost = total;
        this.totalRemaining = this.reportItem.totalUnspent.toNumber() - this.totalCost
    }

    calcRemaining(){
        return this.reportItem.totalUnspent.valueOf()
    }

    mounted(){
        this.totalRemaining = this.reportItem.totalUnspent.toNumber();
    }

    onUserReady(uid: string | null) : void {
        return;    
    }
    componentClass(): string {
        return "live-estimator"
    }
 
}
