
import { Chart } from "chart.js"
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator"
import { uuid } from "vue-uuid";
import { ReportItem } from "possibleme-db/lib/util/BusinessUtil";
import ObjectPopup from "@/components/phoenix/ObjectPopup.vue"
import LiveEstimator from "@/components/possibleme/widgets/LiveEstimator.vue"
import { PopupToggler } from "../phoenix/ObjectPopup.vue";
import { displayMoney } from "possibleme-db/lib/util/BigNum"
import MeAction from "../possibleme/helper/MeAction.vue"

@Options({
    components : {
        ObjectPopup,
        LiveEstimator,
        MeAction
    }
})
export default class PossiblePie extends Vue {
    chartId = uuid.v4()
    @Prop()
    report! : ReportItem

    @Prop()
    index! : number

    classUnspent! : string

    toggler! : PopupToggler

    styleUnspent = {
        "background-color": "var(--color--accent)",
        "color":"white",
    }
    onToggler(toggler: PopupToggler) {
        this.toggler = toggler
    }
    onEstimator() {
        this.toggler(true)
    }

    mounted() {
        let ctx = document.getElementById(this.chartId) as any;
        const context = ctx.getContext("2d");

        const spent = Number(this.report.totalSpent.valueOf()) 
        const planned = Number(this.report.totalPlanned.valueOf()) 
        let unspent = Number(this.report.totalUnspent.valueOf()) 
        let remain = 0;
        const unspentAbs = Math.abs(unspent)
        if(unspent >= -0.05){
            unspent = unspentAbs 
        }
        else {
            unspent = 0
            remain = unspentAbs
        }
        const reportData : any = {
            type : "pie",
            data : {
                labels : ["Spent", "Planned", "Unspent"],
                datasets : [{
                    data : [spent, planned, unspent, remain],
                    backgroundColor: [
                        '#37abc8',
                        '#003380',
                        '#aad400',
                        '#f23873',
                    ]
                }],
            },
        }
        if(remain > 0)
            reportData.data.labels[3] = "Over Budget"
        const myChart = new Chart(context, reportData)
        
    }
    displayAmount = displayMoney
}
