<template>
    <div class='comp-budget pb-4'>
        <PlanBanner></PlanBanner>
        <h1 class='indent'>Budgets</h1>
        <MeAction class='indent' @click="printReport" :labelText="'Generate Budget Report'"></MeAction>
        <main>
            <div class='indent compose main-frame'>
            <div class='main-frame--header' >
            </div>
            <div>
                <div class='budget-list'>
                    <div class='pie-item' v-for="(item, index) in reportItems" :key="`item:${index}`">
                        <PossiblePie :index="index" :report="item" class='possible-pie'>
                        </PossiblePie> 
                    </div>
                </div> 
            </div>
        </div>   
        </main>
    </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import PossiblePie from "@/components/graph/PossiblePie.vue"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject, { ObjectHelper } from "@/scripts-core/cloud/CloudObject";
import * as VClient from "@/scripts-possibleme/client/PossibleClient"
import { emptyPlan, Support } from "possibleme-db";
import { BudgetReport, generateReport, ReportItem } from "possibleme-db/lib/util/BusinessUtil"
import PlanBanner from "@/components/possibleme/PlanBanner.vue"
import MeAction from "../../components/possibleme/helper/MeAction.vue"
import { logEventPageView, PAGE_BUDGET } from "@/possibleme/PossibleAnalytics";

@Options({
    components :{
        PossiblePie,
        PlanBanner,
        MeAction
    }
})
export default class VueBudget extends FireComponent {
    supports : CloudObject<Support>[] = []
    report : BudgetReport = generateReport(emptyPlan(), []);
    reportItems : ReportItem[] = []

    componentClass = ()=> "possiblevue-budget"

    mounted() {
        logEventPageView(PAGE_BUDGET)
    }
    
    onUserReady(uid: string|null): void {
        VClient.subscribeCurrentSupports(this.getContext(), this.onSupports);
    
        return;
    }
    onSupports(supports : CloudObject<Support>[] | null){
        if(!supports) return
        this.supports = supports;
         const flatSupport = ObjectHelper.convertRaw(supports);
        
        const plan = VClient.getCurrentPlan();
        if(plan != null){
            this.report = generateReport(plan.value(this), flatSupport);
            this.reportItems = this.report.listItems().sort((a,b)=>{
                const comp = a.category.display < b.category.display
                if(comp)
                    return -1;
                return 1;
            });
        }
       // 
    }
    printReport() {
        const res = this.$router.resolve({
            path : "/report/budget",
            query : {
                planId : VClient.getCurrentPlan()?.id() 
            }
        })
        window.open(res.href, '_blank');
    }
}
</script>

<style scoped>
.possible-pie {
    max-width: 350px;
    flex-grow: 1;
}
.budget-list {
    flex-wrap: wrap;
    display: flex;
    gap: 2em;
    max-width: 800px;
}
.comp-budget {
    z-index: 300px;
    background-color: var(--pfill);
}
.pie-item {
    
}
</style>