
import { Options } from "vue-class-component"
import PossiblePie from "@/components/graph/PossiblePie.vue"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject, { ObjectHelper } from "@/scripts-core/cloud/CloudObject";
import * as VClient from "@/scripts-possibleme/client/PossibleClient"
import { emptyPlan, Support } from "possibleme-db";
import { BudgetReport, generateReport, ReportItem } from "possibleme-db/lib/util/BusinessUtil"
import PlanBanner from "@/components/possibleme/PlanBanner.vue"
import MeAction from "../../components/possibleme/helper/MeAction.vue"
import { logEventPageView, PAGE_BUDGET } from "@/possibleme/PossibleAnalytics";

@Options({
    components :{
        PossiblePie,
        PlanBanner,
        MeAction
    }
})
export default class VueBudget extends FireComponent {
    supports : CloudObject<Support>[] = []
    report : BudgetReport = generateReport(emptyPlan(), []);
    reportItems : ReportItem[] = []

    componentClass = ()=> "possiblevue-budget"

    mounted() {
        logEventPageView(PAGE_BUDGET)
    }
    
    onUserReady(uid: string|null): void {
        VClient.subscribeCurrentSupports(this.getContext(), this.onSupports);
    
        return;
    }
    onSupports(supports : CloudObject<Support>[] | null){
        if(!supports) return
        this.supports = supports;
         const flatSupport = ObjectHelper.convertRaw(supports);
        
        const plan = VClient.getCurrentPlan();
        if(plan != null){
            this.report = generateReport(plan.value(this), flatSupport);
            this.reportItems = this.report.listItems().sort((a,b)=>{
                const comp = a.category.display < b.category.display
                if(comp)
                    return -1;
                return 1;
            });
        }
       // 
    }
    printReport() {
        const res = this.$router.resolve({
            path : "/report/budget",
            query : {
                planId : VClient.getCurrentPlan()?.id() 
            }
        })
        window.open(res.href, '_blank');
    }
}
